<template>
    <!-- 摄影订单 -->
    <div>
        <!-- 搜索栏 & 标签页 -->
        <order-search
            :start="startDate"
            :end="endDate"
            @handle-start="handleStart"
            @handle-end="handleEnd"
            @handle-search="getData">
			<template v-slot:filtrateStyle>
				<el-dropdown style="margin: 10px;display: flex;justify-content: center;"  @command="handleStyle">
				  <el-button type="primary">
				    {{styleModel.value}}<i class="el-icon-arrow-down el-icon--right"></i>
				  </el-button>
				  <el-dropdown-menu slot="dropdown" style="height: 200px; overflow: auto;">
				    <el-dropdown-item :command="tiem" v-for="tiem in style">{{tiem.value}}</el-dropdown-item>
				  </el-dropdown-menu>
				</el-dropdown>
			</template>
			<template v-slot:filtrateDistance>
				<el-dropdown style="margin:10px;" @command="handleDistance">
				  <el-button type="primary">
					  {{distanceModel.value}}<i class="el-icon-arrow-down el-icon--right"></i>
				  </el-button>
				 <el-dropdown-menu slot="dropdown" style="height: 200px; overflow: auto;">
				   <el-dropdown-item :command="tiem" v-for="tiem in distance">{{tiem.value}}</el-dropdown-item>
				  </el-dropdown-menu>
				</el-dropdown>
			</template>
        </order-search>
        <order-tabs
            v-model="tab"
            :tabs-list="tabList"
            @tab-click="getData">
        </order-tabs>
        <!-- 表格 -->
        <center-order-table
            table-title="场地订单"
            :table-list="orderTableList">
            <!-- 价格 -->
            <template #price="{price}">
                <div>{{"¥ " + price}}</div>
            </template>
            <!-- 实付款 -->
            <template #payment="{price}">
                <div>{{"¥ "+ price}}</div>
            </template>
            <!-- 交易状态 -->
            <template #status="{status, id}">
                <div>{{status | ostatus}}</div>
                <router-link
                    :to="{
                        name: 'photographPay',
                        query:{id}
                    }">
                    订单详情
                </router-link>
            </template>
            <!-- 操作 -->
            <template #handle="{row}">
                <div>
                    <!-- 接单 -->
                    <template v-if="row.orderStatus == 1">
                        <el-popover
                            trigger="hover"
                            placement="top">
                            <el-button
                                type="primary"
                                @click="handleReceipt(row.orderID, 1)"
                                :disabled="!!row.lensman">
                                摄影师身份接单
                            </el-button>
                            <el-button
                                type="primary"
                                v-if="row.needDresser==1"
                                @click="handleReceipt(row.orderID, 2)"
                                :disabled="!!row.dresser">
                                化妆师身份接单
                            </el-button>
                            <el-button slot="reference" type="text">接单</el-button>
                        </el-popover>
                    </template>
                    <!-- 上传样片 -->
                    <a href="javascript:;"
                        class="center-photography-handle"
						v-show="row.lensman===user_id"
                        v-if="row.orderStatus==3 || row.orderStatus==4 || row.orderStatus==9"
                        @click="$router.push({name:'sample',query:{id:row.orderID,status: row.sampleStatus}})">
                        {{row.orderStatus==9? "查看精片": "上传样片"}}
                    </a>
					<!-- <div>{{row.lensman}},{{user_id}}</div> -->
                    <!-- 上传地址 -->
                   <!-- <a href="javascript:;"
                        class="center-photography-handle"
                        v-if="row.orderStatus == 5"
                        @click="handleUpload(row.orderID)">
                        上传地址
                    </a> -->

					<!-- 完成订单 -->
					<a href="javascript:;"
						v-show="row.completed==0"
					    class="center-photography-handle"
					    v-if="row.orderStatus == 5"
						@click="handleStatusphotography(row.orderID)"
						>
					   确认完成
					</a>
					<a href="javascript:;"
						v-show="row.completed==1"
					    class="center-photography-handle"
					    v-if="row.orderStatus == 5"
						>
					   已确认完成
					</a>


                    <!-- 完成拍摄 -->
                    <a href="javascript:;"
						v-show="row.takedPhoto==0"
                        class="center-photography-handle"
                        v-if="row.orderStatus == 2 && user_type == 21"
                        @click="handleConfirm(row.orderID)">
                        完成拍摄
                    </a>
					<a href="javascript:;"
						v-show="row.takedPhoto==1"
					    class="center-photography-handle"
					    v-if="row.orderStatus == 2 && user_type == 21"
					    >
					    已确认
					</a>
                </div>
            </template>
        </center-order-table>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import order from '@/mixins/centerOrder'

export default {
    filters:{
        // 订单状态过滤器
        ostatus(val){
            switch(val){
                case 0:
                    return "待付款";
                    break;
                case 1:
                    return "待接单";
                    break;
                case 2:
                    return "待服务";
                    break;
                case 3:
                    return "样片上传";
                    break;
                case 4:
                    return "样片挑选";
                    break;
                case 5:
                    return "作品上传";
                    break;
                case 9:
                    return "已完成";
                    break;
                case -1:
                    return "已取消";
                    break;
                case -2:
                    return "已过期";
                    break;
            }
        },
    },
    mixins: [ order ],
    data() {
        return {
			tab:"-3",
            tabList: [
                {
                    value: "-3",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "待接单",
                },
                {
                    value: "2",
                    label: "待服务",
                },
                {
                    value: "3",
                    label: "样片上传",
                },
                // {
                //     value: "5",
                //     label: "上传",
                // },
                {
                    value: "9",
                    label: "已完成",
                },
                {
                    value: "-1",
                    label: "已取消",
                },

            ],
			styleModel:{
				value:'风格'
			},
			distanceModel:{
				value:'距离'
			},
			style:[],
			distance:[]
        }
    },
    computed: mapState({
        user_type: state => state.user.user_type,
        user_id: state => state.user.user_id,
        orderTableList: state => state.order.orderTableList,
		condition_styleDistance: state => state.user.condition_styleDistance
    }),
	watch:{
		condition_styleDistance(){
			this.style = this.condition_styleDistance.style
			this.distance = this.condition_styleDistance.distance
		}
	},
	mounted() {
		this.getCondition();
	},
    methods: {
        ...mapMutations([
            'handleLoading',
            'setCenterOrder'
        ]),
        ...mapActions([
            'handleUploadLink',
            'getCenterOrder',
            'handleOrderStatus',
			'getCondition',
			"handleSampleStatus",
        ]),
		//新的订单已经完成
		handleStatusphotography(orderID) {
		    this.$confirm('是否确定完成？', '提示' ,{
		        confirmButtonText: '确定',
		        cancelButtonText: '取消',
		        type: 'warning'
		    }).then(() => {
		        this.handleLoading(true);

		        let url = '/usercenter/finishedselect',
		            params = this.$qs.stringify({
		                userID: this.user_id,
		                orderID
		            });

		        return this.handleOrderStatus({
		            url,
		            params
		        })
		    }).then(res => {
		        let code = res.data.code == 200;
		        this.$message({
		            showClose: true,
		            message: res.data.message,
		            type: code? 'success': 'error'
		        })
		        code && this.getData();
		    }).catch(() => {
		    }).finally(() => {
		        this.handleLoading(false);
		    })
		},

		//确认完成订单
		photographyFinish(id){
			let status = 99,
			orderID = id,
			userID = this.user_id;
			this.handleSampleStatus({
			    status,
			    orderID,
				userID,

			}).then(res => {
					console.log(res)
			 })
		},
		 handleStyle(command) {
			this.styleModel = command
		 },
		 handleDistance(command) {
		 	this.distanceModel = command
		 },
        getParams() {
            let userID = this.user_id,
                fromTime = this.startDate,
                toTime = this.endDate,
				style = this.styleModel.value == '风格'? '':this.styleModel.id,
				distance = this.distanceModel.value == '距离'? '':this.distanceModel.id,
                orderStatus = this.tab == '-3'? '': this.tab;
            return this.$qs.stringify({
                userID,
                fromTime,
                toTime,
				style,
				distance,
                orderStatus,
                type: 1
            })
        },
        getData() {
            let url = 'usercenter/bookorder',
                params = this.getParams();
            this.getCenterOrder({
                url,
                params
            })
        },
        handleStatus(url, params, msg) {
            this.$confirm(msg, '提示' ,{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleLoading(true);

                return this.handleOrderStatus({
                    url,
                    params
                })
            }).then(res => {
                let code = res.data.code == 200;

                this.$message({
                    showClose: true,
                    message: res.data.message,
                    type: code? 'success': 'error'
                })

                code && this.getData();
            }).catch(() => {
            }).finally(() => {
                this.handleLoading(false);
            })
        },
        handleReceipt(orderID, takerType) {
            let url = 'usercenter/accessOrder',
                msg = '是否确定接单？',
                params = this.$qs.stringify({
                    userID: this.user_id,
                    type: 'PH',
                    orderID,
                    takerType
                });
            this.handleStatus(url, params, msg);
        },
		//完成拍摄
        handleConfirm(orderID) {
            let url = 'usercenter/finishedshoot',
                msg = '是否确定完成？',
                params = this.$qs.stringify({
                    userID: this.user_id,
                    orderID,
                });
            this.handleStatus(url, params, msg);
        },
        handleUpload(orderID){ // 摄影师上传地址
            let downloadurl = ''
            this.$prompt('请输入下载地址', '下载地址', {
                confirmButtonText: '下一步',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                downloadurl = value;
                if(!value){
                    this.$message({
                        showClose: true,
                        message: "您还未输入下载地址!",
                        type: "error"
                    })
                    return
                }

                return this.$prompt("请输入下载密码", "下载密码", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                })
            }).then(({ value: downloadkey }) => {
                if(!downloadkey){
                    this.$message({
                        showClose: true,
                        message: "您还未输入下载密码!",
                        type: "error"
                    })
                    return
                }

                return this.handleUploadLink({
                    orderID,
                    downloadurl,
                    downloadkey
                })
            }).then(res => {
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.type? "success": "error"
                })
            }).catch(() => {})
        }
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next(vm => {
            vm.getData();
        })
    },
    beforeRouteLeave (to, from, next) {
        this.setCenterOrder([])
        next();
    }
}
</script>
